import styles from './shareMultiImages.module.less';
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import MarkdownWithHighlight from '@/components/MarkdownWithHighlight/MarkdownWithHighlight';
import AudioPlayingIcon from '@/assets/img/chat_component/audio_playing.svg';
import PlayAudioIcon from '@/assets/img/chat_component/play_input_message.svg';
import GenAudioLoading from '@/assets/img/chat_component/gen_audio_loading.svg';
import MultimediaAPI from '@/api/multimedia';
import { message } from 'antd';
import BookIcon from '@/assets/img/share_page/book_icon.svg';
import PencilIcon from '@/assets/img/share_page/pencil_icon.svg';
import EductionIcon from '@/assets/img/share_page/education_icon.svg';
import MultiImagesInfo from '@/base/ShareData/MultiImagesInfo';

interface ShareMultiImagesProps {
    multiImagesInfo: MultiImagesInfo;
}

const ShareMultiImages: React.FC<ShareMultiImagesProps> = ({ multiImagesInfo }) => {
    // 使用 useMediaQuery 钩子来检测屏幕宽度  
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isNotMobile = useMediaQuery({ query: '(min-width: 769px)' });
    const ttsAPI = new MultimediaAPI();
    const [isPlayingContent, setIsPlayingContent] = useState(false);
    const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
    const [audio] = useState(new Audio(multiImagesInfo.contentVoiceUrl));
    const [contentAudioUrl, setContentAudioUrl] = useState(multiImagesInfo.contentVoiceUrl || '');

    useEffect(() => {
        audio.addEventListener('ended', () => {
            setIsPlayingContent(false);
        });

        return () => {
            audio.pause();
            audio.src = "";
            audio.load();
        }
    }, [audio]);

    //icon显示，播放时显示暂停，暂停时显示播放，生成音频时显示加载
    const showAudioIcon = () => {
        if (isPlayingContent) {
            return AudioPlayingIcon;
        } else if (isGeneratingAudio) {
            return GenAudioLoading;
        } else {
            return PlayAudioIcon;
        }
    }

    const genVoice = async (text: string) => {
        let res: any;
        try {
            setIsGeneratingAudio(true);
            res = await ttsAPI.genVoiceAndUpload(
                {
                    text: text,
                    voice_type: 'BV700_streaming'
                }, 'tts');

            // 更新音频源和状态  
            setContentAudioUrl(res.file_url); // 存储生成的音频链接  
            audio.src = res.file_url;

        } catch (error) {
            console.error('genVoiceAndUpload:', error);
            message.error('语音生成失败');
        } finally {
            setIsGeneratingAudio(false);
        }
    }

    //点击播放按钮
    const clickAudioIcon = async () => {
        if (isGeneratingAudio) return;

        if (isPlayingContent) {
            audio.pause();
            setIsPlayingContent(false);
        } else {
            // 检查现有的音频源  
            if (contentAudioUrl) { // 使用存储的音频链接  
                audio.src = contentAudioUrl;
            } else {
                await genVoice(multiImagesInfo.content);
            }
            audio.play().then(() => {
                setIsPlayingContent(true);
            }).catch(err => {
                console.error('Error playing audio:', err);
            });
        }
    }

    return (
        <>
            {isNotMobile &&
                <div
                    className={styles.pcPage}
                >
                    <div className={styles.tabletDesktopLayout}>
                        <div className={styles.content}>
                            <div className={styles.imgBox}>
                                {multiImagesInfo.imgUrl1.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl1} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl1} className={styles.image} />
                                }
                                {multiImagesInfo.imgUrl2.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl2} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl2} className={styles.image} />
                                }
                                {multiImagesInfo.imgUrl3.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl3} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl3} className={styles.image} />
                                }
                                {multiImagesInfo.imgUrl4.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl4} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl4} className={styles.image} />
                                }
                                {multiImagesInfo.imgUrl5.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl5} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl5} className={styles.image} />
                                }
                                {multiImagesInfo.imgUrl6.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl6} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl6} className={styles.image} />
                                }

                            </div>
                            <div className={styles.textArea}>
                                <div className={styles.title}>{multiImagesInfo.title}</div>
                                <div className={styles.textContent}>
                                    <MarkdownWithHighlight content={multiImagesInfo.content} />
                                </div>
                                <div className={styles.playContentBtn}>
                                    <img
                                        src={showAudioIcon()}
                                        alt="audio"
                                        onClick={clickAudioIcon}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                isMobile &&
                <div
                    className={styles.mobilePage}>
                    <div
                        className={styles.mobileLayout}
                    >
                        <div className={styles.containerBox}>
                            <div className={styles.title}>
                                {multiImagesInfo.title}
                            </div>
                            <div className={styles.imgBox}>
                                {multiImagesInfo.imgUrl1.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl1} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl1} className={styles.image} />
                                }
                            </div>
                            <div className={styles.imgBox}>
                                {multiImagesInfo.imgUrl1.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl2} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl2} className={styles.image} />
                                }
                            </div>
                            <div className={styles.imgBox}>
                                {multiImagesInfo.imgUrl1.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl3} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl3} className={styles.image} />
                                }
                            </div>
                            <div className={styles.imgBox}>
                                {multiImagesInfo.imgUrl1.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl4} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl4} className={styles.image} />
                                }
                            </div>
                            <div className={styles.imgBox}>
                                {multiImagesInfo.imgUrl1.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl5} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl5} className={styles.image} />
                                }
                            </div>
                            <div className={styles.imgBox}>
                                {multiImagesInfo.imgUrl1.endsWith('mp4') ?
                                    <video src={multiImagesInfo.imgUrl6} controls className={styles.image} /> :
                                    <img src={multiImagesInfo.imgUrl6} className={styles.image} />
                                }
                            </div>
                            <div className={styles.textContentBox}>
                                <div
                                    className={styles.textContent}
                                    style={{
                                        backgroundColor: multiImagesInfo.contentBackgroundColor,
                                        ...(multiImagesInfo.contentTextAlign !== "" && {
                                            textAlign: multiImagesInfo.contentTextAlign as 'left' | 'right' | 'center' | 'justify'
                                        })
                                    }}
                                >
                                    <MarkdownWithHighlight content={multiImagesInfo.content} />
                                </div>
                                <div className={styles.playContentBtn}>
                                    <img
                                        src={showAudioIcon()}
                                        alt="audio"
                                        onClick={clickAudioIcon}
                                    />
                                </div>
                            </div>
                            <div className={styles.bookIcon}>
                                <img src={BookIcon} alt="book" />
                            </div>
                            <div className={styles.pencilIcon}>
                                <img src={PencilIcon} alt="pencil" />
                            </div>
                            <div className={styles.educationIcon}>
                                <img src={EductionIcon} alt="education" />
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default ShareMultiImages;
