import DataDefinition from "../DataDefinition";

class MultiImagesInfo extends DataDefinition {
    title: string;
    content: string;
    imgUrl1: string;
    imgUrl2: string;
    imgUrl3: string;
    imgUrl4: string;
    imgUrl5: string;
    imgUrl6: string;
    contentVoiceUrl: string;
    shareDescription: string;
    contentBackgroundColor: string;
    contentTextAlign: string;

    constructor({
        title = "",
        content = "",
        imgUrl1 = "",
        imgUrl2 = "",
        imgUrl3 = "",
        imgUrl4 = "",
        imgUrl5 = "",
        imgUrl6 = "",
        contentVoiceUrl = "",
        shareDescription = "",
        contentBackgroundColor = "",
        contentTextAlign = ""
    }: Partial<MultiImagesInfo> = {}) {
        super();
        this.allowedFields = ['title', 'content', 'imgUrl1', 'imgUrl2', 'imgUrl3', 'imgUrl4',
            'imgUrl5', 'imgUrl6', 'contentVoiceUrl', 'shareDescription', 'contentBackgroundColor',
            'contentTextAlign'];
        this.title = title;
        this.content = content;
        this.imgUrl1 = imgUrl1;
        this.imgUrl2 = imgUrl2;
        this.imgUrl3 = imgUrl3;
        this.imgUrl4 = imgUrl4;
        this.imgUrl5 = imgUrl5;
        this.imgUrl6 = imgUrl6;
        this.contentVoiceUrl = contentVoiceUrl;
        this.shareDescription = shareDescription;
        this.contentBackgroundColor = contentBackgroundColor;
        this.contentTextAlign = contentTextAlign;
    }
}

export default MultiImagesInfo;